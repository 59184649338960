import {
	ThemeProvider,
	VendorDefaultImage,
} from '@xo-union/tk-component-vendor-card';
import React, { FC } from 'react';
import Styles from './styles.scss';

const MediaNotAvailable: FC = () => {
	return (
		<ThemeProvider>
			<div className={Styles.mediaNotAvailableContainer}>
				<VendorDefaultImage />
			</div>
		</ThemeProvider>
	);
};

export default MediaNotAvailable;
