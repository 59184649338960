import { Row } from '@xo-union/tk-component-grid';
import React, { VFC } from 'react';
import Recommendations from '..';
import type { CardContainerProps } from '../../types';

type ListProps = Omit<CardContainerProps, 'algorithmType'>;

const List: VFC<ListProps> = (props) => (
	<Row>
		<Recommendations {...props} />
	</Row>
);

export default List;
