import { selectAssignment } from '@redux/experiments/selectors/index';
import { SortPillAssignment } from '@typings/experiments';
import { State } from 'types/redux';

const newSortPillAssignmentSelector = (state: State) => {
	if (state.viewport.isMobile) {
		return null;
	}

	return selectAssignment(state, ['sortPill']) as SortPillAssignment;
};

export { newSortPillAssignmentSelector };
