import { Column } from '@xo-union/tk-component-grid';
import React, { VFC, useContext } from 'react';
import { WidgetContext } from '../../../index';
import Styles from '../../styles.scss';
import type { CardProps } from '../../types';
import Card from './Card';

const CardColumn: VFC<CardProps> = (props) => {
	const {
		cardType,
		displayTheme,
		impressionType,
		index,
		trackingData,
		vendor,
		viewport,
	} = props;
	const contextValue = useContext(WidgetContext);

	const getColumnSizes = () => {
		if (contextValue === 'top') {
			return { xs: '12', md: '6', lg: '4', xl: '3', xxl: '3' };
		}
		if (contextValue === 'center') {
			return { xs: '12', sm: '6', md: '4', lg: '4', xl: '3', xxl: '3' };
		}

		return { xs: '12' };
	};

	const columnSizes: Object = getColumnSizes();

	return (
		<Column
			className={Styles[`${cardType}CardColContainer`]}
			key={vendor.id}
			{...columnSizes}
		>
			<Card
				cardType={cardType}
				displayTheme={displayTheme}
				impressionType={impressionType}
				index={index}
				trackingData={trackingData}
				vendor={vendor}
				viewport={viewport}
			/>
		</Column>
	);
};

export default CardColumn;
