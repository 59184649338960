import { GooglePublisherTag } from '@components/google-publisher-tag';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { isLiteStorefrontSelector } from '@redux/experiments/selectors/lite-storefronts';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { Link } from '@xo-union/tk-component-buttons';
import { Column, Row } from '@xo-union/tk-component-grid';
import { Body1, Body2, Editorial } from '@xo-union/tk-ui-typography';
import cx from 'classnames';
import React, { FC } from 'react';
import { Decorated } from 'types/vendor';
import AnalyticsConstants from '../../../../../constants/analytics';
import { adSlotEnv } from '../../../../../settings';
import TrackableLink from '../../../../components/trackable_link';
import { codeToSlugNameMap } from '../../../../utils/categoryNameMappings';
import algorithms from '../../constants/algorithms';
import Styles from './styles.scss';

interface RenderAdProps {
	vendor: Vendor.Decorated;
	isMobile: boolean;
}

const RenderAd = ({ vendor, isMobile }: RenderAdProps) => {
	const deviceType = isMobile ? 'mobile' : 'desktop';
	const targeting = {
		pos: ['vendor1'],
		'device-type': [deviceType],
		'first-imp': ['false'],
		page: ['1'],
		premium: ['true'],
		'page-type': ['unpaid'],
		mtdevicetype: [deviceType],
		cat: [vendor.categoryCode],
		city: [vendor.marketCode],
		env: adSlotEnv,
	};

	return (
		<div className={Styles.adContainer}>
			<GooglePublisherTag
				id="div-gpt-ad-5183248-1"
				size="fluid"
				targeting={targeting}
			/>
		</div>
	);
};

interface TrackingProps {
	vendorCategory: string;
	sourcePage: string;
	sourceContent: string;
}

interface WrapperProps {
	algorithmType: string;
	widgetType: string;
	children: React.ReactNode;
	className?: string;
	headerText?: string;
	subheaderText?: string;
	seeAllUrl?: string;
	trackingData: {
		properties: TrackingProps;
	};
	widgetName?: string;
}

const carouselContainerClass = (widgetType: string) => {
	let containerClass = Styles.carouselContainer;

	if (widgetType === 'top') {
		containerClass = cx(containerClass, Styles.topCarouselContainer);
	}
	if (widgetType === 'center') {
		containerClass = cx(containerClass, Styles.centerCarouselContainer);
	}

	return containerClass;
};

const titleClass = (algorithmType: string) => {
	return cx(
		Styles.widgetTitle,
		algorithmType === algorithms.PVREC_ANON ? Styles.vendorSearchTitle : '',
	);
};

const vendorTrackingData = (trackingData: TrackingProps) => {
	const {
		vendorCategory: vendorCategoryCode,
		sourcePage,
		sourceContent,
	} = trackingData;

	return {
		event: AnalyticsConstants.CLICK_THROUGH_TO_VENDOR_CATEGORY,
		properties: {
			vendorCategoryCode,
			sourcePage,
			sourceContent,
		},
	};
};

const Wrapper: FC<WrapperProps> = ({
	algorithmType,
	widgetType,
	children = [], // Default value set here
	className = '',
	headerText = '',
	subheaderText = 'Our Featured Vendors',
	seeAllUrl = '#',
	trackingData,
	widgetName = '',
}) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const isMobile = useAppSelector((state) => selectIsMobile(state));
	const seeAllTrackingProps = vendorTrackingData(trackingData.properties);
	// Applying only to the middle (cross-category) widget
	const isLiteStorefrontExperiment =
		useAppSelector(isLiteStorefrontSelector) &&
		(algorithmType === algorithms.PVREC_CROSS_CAT ||
			algorithmType === algorithms.SIMILAR ||
			algorithmType === algorithms.BOW);
	const { track } = useAnalyticsContext();

	return (
		<div
			className={cx(className, Styles.widget)}
			data-algorithm-type={algorithmType}
		>
			<div className={Styles.widgetHeader}>
				<Row noGutters>
					<Column xl>
						{isLiteStorefrontExperiment ? (
							<>
								<div className={Styles.header}>
									<Editorial as="h1" bold className={titleClass(algorithmType)}>
										{algorithmType === algorithms.BOW &&
											'Nearby vendors you might like'}
										{algorithmType === algorithms.SIMILAR &&
											`Other ${codeToSlugNameMap[
												vendor.categoryCode
											].name.toLowerCase()} nearby`}
										{algorithmType === algorithms.PVREC_CROSS_CAT &&
											'Users who considered this also checked out'}
									</Editorial>
									<Body2 className={Styles.subheaderText}>
										Our featured vendors
									</Body2>
								</div>
								{!isMobile && widgetName !== 'lite-storefront' && (
									<Link
										size="md"
										color="secondary"
										href={seeAllUrl}
										className={Styles.widgetSeeAllLink}
										onClick={() => track(seeAllTrackingProps)}
									>
										See all
									</Link>
								)}
							</>
						) : (
							<>
								<Body1 as="h1" bold className={titleClass(algorithmType)}>
									{headerText}
								</Body1>
								<div className={Styles.widgetSeeAllLink}>
									<TrackableLink
										websiteUrl={seeAllUrl}
										text="See All"
										trackingData={seeAllTrackingProps}
									/>
								</div>
							</>
						)}
					</Column>
				</Row>
				{algorithmType !== algorithms.PVREC_ANON && (
					<Row noGutters>
						<Column xl>
							{!isLiteStorefrontExperiment && <sub>{subheaderText}</sub>}
						</Column>
					</Row>
				)}
			</div>
			{algorithmType === algorithms.BOW && (
				<RenderAd vendor={vendor} isMobile={isMobile} />
			)}
			<div className={carouselContainerClass(widgetType)}>
				<div className={Styles.carouselList}>{children}</div>
				{isLiteStorefrontExperiment &&
					(isMobile || widgetName === 'lite-storefront') && (
						<Link
							size="md"
							color="secondary"
							href={seeAllUrl}
							className={Styles.seeAllButton}
							onClick={() => track(seeAllTrackingProps)}
						>
							See all
						</Link>
					)}
			</div>
		</div>
	);
};

export { Wrapper };
