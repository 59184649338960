import { MutableRefObject } from 'react';

interface SharedParams {
	evt: googletag.events.SlotRenderEndedEvent;
}

interface CheckForEmptyGoogleAd {
	evt: googletag.events.SlotRenderEndedEvent;
	setIsAdEmpty: CustomHooks.UseGPTParams['setIsAdEmpty'];
}

interface CreateBuzzBadgeListParams extends SharedParams {
	setBuzzBadgeIds: CustomHooks.UseGPTParams['setBuzzBadgeIds'];
}

interface HandleSlotRenderEndedParams extends SharedParams {
	slotRef: MutableRefObject<googletag.Slot | null>;
	setBuzzBadgeIds: CustomHooks.UseGPTParams['setBuzzBadgeIds'];
	setIsAdEmpty: CustomHooks.UseGPTParams['setIsAdEmpty'];
}

const checkForEmptyGoogleAd = ({
	evt,
	setIsAdEmpty,
}: CheckForEmptyGoogleAd) => {
	if (setIsAdEmpty) {
		setIsAdEmpty(evt.isEmpty);
	}
};

const createBuzzBadgeList = ({
	evt,
	setBuzzBadgeIds,
}: CreateBuzzBadgeListParams) => {
	if (setBuzzBadgeIds && !evt.isEmpty) {
		setBuzzBadgeIds((prevState) => {
			return [...prevState, evt.slot.getSlotElementId()];
		});
	}
};

const handleSlotRenderEnded = ({
	evt,
	setBuzzBadgeIds,
	setIsAdEmpty,
	slotRef,
}: HandleSlotRenderEndedParams) => {
	if (slotRef.current === evt.slot) {
		createBuzzBadgeList({ evt, setBuzzBadgeIds });
		checkForEmptyGoogleAd({ evt, setIsAdEmpty });
	}
};

export { checkForEmptyGoogleAd, createBuzzBadgeList, handleSlotRenderEnded };
