import { LegacyRef, MutableRefObject, RefCallback } from 'react';

/**
 * Merges two or more MutableRefObjects or LegacyRefs into a single RefCallbacks.
 * This can be helpful when you want to combine a ref from a parent component to a component local ref.
 *
 * @template T
 * @param {(...Array<MutableRefObject<T> | LegacyRef<T>>)} refs
 * @return {RefCallback<T>}
 */
const mergeRefs =
	<T = unknown>(
		...refs: Array<MutableRefObject<T> | LegacyRef<T>>
	): RefCallback<T> =>
	(value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
				return;
			}
			if (ref != null) {
				// eslint-disable-next-line no-param-reassign
				(ref as MutableRefObject<T | null>).current = value;
			}
		});
	};

export default mergeRefs;
